// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_bullet__X7sD4{
    font-size: 14px;
    width: px;
    width: 20px;
    height: 10px;
    margin-top: 5px;
    padding-right: 12px;
    margin-left: 8px;

}

.sidebar_marked__g0ZN7{
    margin-left: 25px;
    width: 80%;
    border-radius: 5px;
}

.sidebar_logo__Gsv3r{
     width: 65px;
     height: 65px;
     margin-left: 80;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,gBAAgB;;AAEpB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,kBAAkB;AACtB;;AAEA;KACK,WAAW;KACX,YAAY;KACZ,eAAe;AACpB","sourcesContent":[".bullet{\n    font-size: 14px;\n    width: px;\n    width: 20px;\n    height: 10px;\n    margin-top: 5px;\n    padding-right: 12px;\n    margin-left: 8px;\n\n}\n\n.marked{\n    margin-left: 25px;\n    width: 80%;\n    border-radius: 5px;\n}\n\n.logo{\n     width: 65px;\n     height: 65px;\n     margin-left: 80;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bullet": `sidebar_bullet__X7sD4`,
	"marked": `sidebar_marked__g0ZN7`,
	"logo": `sidebar_logo__Gsv3r`
};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react'
import load from '../assets/images/loading.gif'
import css from '../assets/css/btn.module.css'

class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type
    }
  }
  render() {
    const { type } = this.state
    return (
      <>
        {type === "buff" ? <div className='text-center'>
          <img style={{ marginLeft: '1200px !important;' }} src={load} alt="loading" />
        </div> : <>
        <div className="text-center">
          <div className={css.circle}>
            <div className={css.wave}>
            </div>
          </div>
          </div>
        </>}
      </>
    
    )
  }
}

export default Loading;

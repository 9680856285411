import React, { useState, useEffect } from 'react';

function HeaderConvertDateTime(get_date, data, type = null) {
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  function checkDateFormat(dateString, formatString) {
    const regex = new RegExp(formatString);
    return regex.test(dateString);
  }

  // date convert into format date for example date = 1-1-2023 then it will be converted into 01-01-2023
  // and also convert into year format like = 2023-01-01
  if (data === "time") {
    let time = get_date;

    if (get_date.includes(".")) {
      time = get_date.split(".")[0];
    }

    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format is correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  } else {
    let format = /^\d{4}-\d{2}-\d{2}$/;
    let parts;
    let date;

    if (type === "format") {
      if (checkDateFormat(get_date, format)) {
        date = new Date(get_date);
      } else {
        parts = get_date.includes('/') ? get_date.split('/') : get_date.split('-');
        date = new Date(parts[2], parts[1] - 1, parts[0]);
      }
    } else {
      date = new Date(get_date);
    }

    let day = date.getDate();
    const theDay = day;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day <= 9) {
      day = '0' + day;
    }
    if (month <= 9) {
      month = '0' + month;
    }

    let convert = '';

    if (data === "day") {
      convert = theDay;
    }
    if (data === "Y") {
      convert = year;
    }
    if (data === "date") {
      convert = `${month}-${day}-${year}`;
    }
    if (data === "year") {
        convert = `${month}-${day}-${year}`
    }
    if (data === "month") {
        convert = `${month}-${day}-${year}`
    }
    if (data === "week") {
      let dayIndex = date.getDay();
      convert = dayNames[dayIndex];
    }
    if (data === "monthView") {
      let monthIndex = date.getMonth();
      convert = monthNames[monthIndex];
    }
    

    return convert;
  }
}

export default HeaderConvertDateTime;

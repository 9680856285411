import React, { useState, useEffect } from 'react';

const OnlySecond = ({ seconds = 0 }) => {
  const [remainingTime, setRemainingTime] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const minutesDisplay = Math.floor(remainingTime / 60);
  const secondsDisplay = remainingTime % 60;

  const formattedTime = `${minutesDisplay.toString().padStart(2, '0')}:${secondsDisplay.toString().padStart(2, '0')}`;

  return (
    <div>
      <div>{formattedTime}</div>
    </div>
  );
};

export default OnlySecond;

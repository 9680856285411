import React from 'react'

const CloseButton = ({onClick, width}) => {
  return (
    <button
    type="button"
    id='newclosebuttoncheck'
    style={{
      color: "#5c636a",
      border: "1px solid #5c636a",
      width: "85px",
    }}
    className="btn"
    data-dismiss="modal"
    onClick={onClick}
  >
    Close
  </button>
  )
}

export default CloseButton

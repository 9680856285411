// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_dt__PO\\+lM{
    font-size: 14px;
    font-weight: 600;
}

.header_head__hFTqx{
    padding: 20px;
    background: #47ADFF;
    color: white;
    font-size: 20px;
    font-weight: 500;
    border-radius: 12px;
}

.header_title__2ZFN3{
    background: none !important;
    border: none !important;
}

.header_Title__DR5-1{
    font-size: 28px;
}
.header_subTitle__A8vKC{
    font-size: 16px;
    color: rgb(7, 71, 166);
}

.header_dropdownNav__iZTUf{
    font-size: 18px;
}
.header_dropdownNav2__sTM7g{
    font-size: 15px;
}

@media (min-width:767px) and (max-width:910px){
        .header_Title__DR5-1{
            font-size: 17px;
        }
        .header_subTitle__A8vKC{
            font-size: 12px;
        }
        .header_dropdownNav__iZTUf{
            font-size: 15px;
        }
        .header_dropdownNav2__sTM7g{
            font-size: 13px;
        }
        .header_dt__PO\\+lM{
            font-size: 10px;
        }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;;AAEA;QACQ;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;AACR","sourcesContent":[".dt{\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.head{\n    padding: 20px;\n    background: #47ADFF;\n    color: white;\n    font-size: 20px;\n    font-weight: 500;\n    border-radius: 12px;\n}\n\n.title{\n    background: none !important;\n    border: none !important;\n}\n\n.Title{\n    font-size: 28px;\n}\n.subTitle{\n    font-size: 16px;\n    color: rgb(7, 71, 166);\n}\n\n.dropdownNav{\n    font-size: 18px;\n}\n.dropdownNav2{\n    font-size: 15px;\n}\n\n@media (min-width:767px) and (max-width:910px){\n        .Title{\n            font-size: 17px;\n        }\n        .subTitle{\n            font-size: 12px;\n        }\n        .dropdownNav{\n            font-size: 15px;\n        }\n        .dropdownNav2{\n            font-size: 13px;\n        }\n        .dt{\n            font-size: 10px;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dt": `header_dt__PO+lM`,
	"head": `header_head__hFTqx`,
	"title": `header_title__2ZFN3`,
	"Title": `header_Title__DR5-1`,
	"subTitle": `header_subTitle__A8vKC`,
	"dropdownNav": `header_dropdownNav__iZTUf`,
	"dropdownNav2": `header_dropdownNav2__sTM7g`
};
export default ___CSS_LOADER_EXPORT___;

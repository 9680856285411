import React, { useEffect, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { ShepherdTourContext } from "react-shepherd";
import videoimage from "../../assets/images/video-lesson.png"
import ApiCall from "../Constants/ApiCall";
import { useLocation } from "react-router-dom";
const Tour = (props) => {
  const tours = useContext(ShepherdTourContext);
  const [onboardingButton, setOnBoardingButton] = useState(false);
  const [onboardingButton2, setOnBoardingButton2] = useState(false);
  const location = useLocation()
   const getTimeZone=async()=>{
    let property = localStorage.getItem("property")
            let url = `${props.url}timeZone-show/${property}`
            let data = await ApiCall(url, 'get')
            if(data?.check==='not exists'){
              const body={
                type:"new_entry"
              }
              let data = await ApiCall(url, 'post',body)
              
              if(data && location.pathname ==='/'){
                    tours.start();
              }
            }
            else{
              setOnBoardingButton(true)
            }
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getTimeZone();
    }, 2000); // 5000 milliseconds = 5 seconds

    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  // Empty dependency array to mimic componentDidMount
  useEffect(() => {

    window.addEventListener('popstate', function (event) {
      // Check if the tour is active (you need to define your own way to check the tour state)
      if (tours.isActive()) {
        tours.cancel()
      }
    });
  }, [])
  
 
    return (
      <>

        <img title="Watch Onboarding Tour" style={{width:25,height:25,cursor:"pointer"}} alt="Watch onboarding tour" src={videoimage} onClick={tours.start}/>
      </>
    );
 
  
};

export default Tour;

import credit_card_gear from "../../assets/images/credit_card_gear.png";
import bookmark_manager from "../../assets/images/bookmark_manager.png";
import meeting_room from "../../assets/images/meeting_room.png";
import folder_supervised from "../../assets/images/folder_supervised.png";
import flag from "../../assets/images/flag.png";
import inventory from "../../assets/images/inventory.png";
import beenhere from "../../assets/images/beenhere.png";


const SidebarData = [
  {
    id: 1,
    heading: "Room Management",
    icon: meeting_room,
    subheading: {
      "Room-Type": ["room-types"],
      Rates: ["rates"],
      "Room-Details": ["room-details"],
      "Room-Availability": ["room-availability"],
    },
  },
  {
    id: 2,
    heading: "Reservation Management",
    icon: folder_supervised,
    subheading: {
      "Room-Reservation": ["Room-Reservation"],
      // "Room-Move": ["Room-Move"],
      "Calendar-View": ["calendar-view"],
      "Amenities-Reservation": ["amenities-reservation"],
      // "Payment-Reconciliation": ["payment-reconciliation"],
    },
  },
  {
    id: 3,
    heading: "Reports",
    icon: flag, //some reports comment out because of no data
    subheading: {
      "Payment-Report": ["payment-reports"],
      "Production-Reports": ["production-reports"],
      "Financial-Reports": ["financial-reports"],
      "Account-Balances": ["account-balances"],

      "Key-Reports": ["bookings"],

      // Departures: ['departure-reports'],
      // 'In-House': ['in-house-reports'],
      //'No-Show': ['no-show-reports'],
      //Cancellations: ['cancellation-reports'],
      // "Room-Assignments": ["room-assignments"],
      "Room-Notes": ["notes"],
      //'User-Reconciliation': ['user-reconciliation'],
      "Daily-Revenue": ["Daily-Revenue"],
      "Room-Move-Report": ["room-move"],
      // "Room-Change-Report": ["room-change"],
      Police: ["police-reports"],
      "Sell-Inventory-Report": ["inventory-reports"],

      "Room-Status-Report": ["Room-Status-Report"],
      "Daily-Report": ["Daily-Reports"],
      "Daily-Collection-Report": ["Daily-Collection-Report"],
      "Daily-Collection-Payment": ["Daily-Collection-Payment"],
      "Daily-Deposit-Report": ["Daily-Deposit-Report"],
      "Amenities-Notes": ["Amenities-Notes"],
      "Amenities-Bookings": ["Bookings"],
      "Ledger-Summary": ["Ledger-Summary"],
      "Daily-Summary": ["Daily-Summary"],
      "Daily-Shift-Report": ["Daily-Sift"],
    },
  },
  {
    id: 4,
    heading: "Amenities Management",
    icon: bookmark_manager,
    subheading: {
      "Amenities-Status": ["amenitites-status"],
      "Hotel-Amenities": ["Hotel-Inventory"],
    },
  },
  {
    id: 5,
    heading: "Stock & Inventory",
    icon: inventory,
    subheading: {
      "Buy-Inventory": ["buy-inventory"],
      "Sell-Inventory": ["sell-inventory"],
    },
  },
  {
    id: 6,
    heading: "Booking Engine View",
    icon: beenhere,
    subheading: {
      "Booking-Engine": ["Booking-Engine"],
    },
  },
  {
    id: 7,
    heading: "Options",
    icon: credit_card_gear,
    subheading: {
      "Employee-Management": ["employee"],
      "Subscription-Management": ["Subscription-View"],
      "Property-Management": ["property-details"],
      "Policy-Management": ["guest-policy"],
      "Cancellation-Policy": ["cancellation-policy"],
      Audit: ["audit"],
    },
  },
];

export default SidebarData;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container #sidebar-wrapper {
    transition: width 0.3s ease; /* Add transition effect */
     /* Initial width when expanded */
     width: 240px;
  }
  
  .sidebar-container.collapsed #sidebar-wrapper {
    width: 70px !important; /* Width when collapsed */
  }


  @media screen and (max-width:1101px) {
    .sidebar-container{
      height: 100vh;
    }
  }

`, "",{"version":3,"sources":["webpack://./src/assets/css/sidebar.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B,EAAE,0BAA0B;KACtD,gCAAgC;KAChC,YAAY;EACf;;EAEA;IACE,sBAAsB,EAAE,yBAAyB;EACnD;;;EAGA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".sidebar-container #sidebar-wrapper {\n    transition: width 0.3s ease; /* Add transition effect */\n     /* Initial width when expanded */\n     width: 240px;\n  }\n  \n  .sidebar-container.collapsed #sidebar-wrapper {\n    width: 70px !important; /* Width when collapsed */\n  }\n\n\n  @media screen and (max-width:1101px) {\n    .sidebar-container{\n      height: 100vh;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const ConvertLatter = (text, type) =>{
    if(type === 'first'){
    // text = text.charAt(0).toUpperCase() +
    // text.slice(1)
    
    if(/\b[\w_]/g.test(text)){
        text = text?.replace(/\b[\w_]/g, function(match) {
            return match.toUpperCase();
          });
        }
    
        if(/(-\w)/g.test(text)){
            
            text = text?.replace(/(-\w)/g, function(match) {
                return match.toUpperCase();
              });
        }
    
    }
    if(type==='remove'){
        text = text?.replace(/[-_]/g, ' ');
    }
    if(type==="capital"){
        var sentences = text.split('. ');
        for (var i = 0; i < sentences.length; i++) {
            sentences[i] = sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1);
        }
        text = sentences.join('. ');
    }

    return text
}  

export default ConvertLatter

  const clearLocalStorage = () => {
    localStorage.removeItem("logintoken");
    localStorage.removeItem("property");
    localStorage.removeItem("collapsed");
    sessionStorage.removeItem("profilePictureUrl");
    sessionStorage.removeItem("persistentFormData");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("propertyData");
    sessionStorage.removeItem("SalesData");
    sessionStorage.removeItem("editPolicy");
    sessionStorage.removeItem("AddPolicy");
    sessionStorage.removeItem("propertyDataAdd");
    sessionStorage.removeItem("propertyDataEdit");
    sessionStorage.removeItem("GuestData");
    sessionStorage.removeItem("CheckInData");
    sessionStorage.removeItem("NotesData");
  };

async function ApiCall(url, method, context = null,tokenCheck=null,status=null) {
  console.log("url = ", url)
  let header = {
    'content-type': "application/json"
  }
  if (localStorage.getItem("logintoken")) {
    // get token and do anything
    console.log("property = ", localStorage.getItem("property"))
    if (localStorage.getItem("property") === undefined || localStorage.getItem("property") === "" || localStorage.getItem("property") == "error") {
      localStorage.removeItem("logintoken")
      window.location.href = "/login";
    }

    const token = (JSON.parse(localStorage.getItem("logintoken")))
    console.log("token = ", url, token, token['token'])
    if (tokenCheck === null){
      if(token !== null){
      
      header['Authorization'] = "Bearer " + token['token']
    }
  
    }
  }
 
  const Status = (data, response) =>{
    
    if (data.status !== undefined && status !==null){
      response['RStatus'] = data.status
    }

    if (data.status==403){
      console.log(window.location.href)
      console.log(window.location.href.split("/"))
      let blocks = window.location.href.split("/")
      clearLocalStorage();
      window.location.replace(`${blocks[0]}//${blocks[2]}/login`)
    }
  }


  if (method === "post") {
    let data = await fetch(url, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(context),
    })
    let response = await data.json()
    
    Status(data, response)
    return response
  }

  else if (method === "put") {
    let data = await fetch(url, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(context),
    })
    let response = await data.json()

    Status(data, response)
    return response
  }

  else if (method === "delete") {
    let data = await fetch(url, {
      method: 'DELETE',
      headers: header,
      body: JSON.stringify(context),
    })
    let response = await data.json()

    Status(data, response)
    return response
  }

  else {
    let data = await fetch(url, {
      method: 'GET',
      headers: header,
    })
    let response = await data.json()
    Status(data, response)
    return response
  }

}
export default ApiCall;
import React from "react";
import Header from "./../common/header";
import Sidebar from "./../common/sidebar";
import "../assets/css/dashboard.css";
import ApiCall from "../Pages/Constants/ApiCall";
import SidebarData from "../Pages/Constants/SidebarData";
import ChildSide from "../Pages/Constants/ChildSidebar";
import ConvertLatter from "../Pages/Common_Fun/ConvertLatter";
import moment from "moment";
// import cogoToast from 'cogo-toast';
import { Navigate } from "react-router-dom";
// import { Preloader, Bars } from 'react-preloader-icon';
import { Toaster, toast } from "react-hot-toast";
import {
  checkoutTimer,
  notificationTImer,
} from "../Pages/Constants/TimerConstants";
import Loading from "../images/Loading";

const adminLayout = (ChildComponent) => {
  class AdminLayout extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        // leftValue:JSON.parse(localStorage.getItem("collapsed")),
        pageLoaded: false,
        timeloaded:false,
        saveLeadClickEvent: "",
        date: "",
        time: "",
        role: "",
        roleName: "",
        url: props.url,
        title: "",
        name: "",
        sendDict: {},
        dName: props.name,
        auditpage: false,
        head: "",
        permission: [],
        auditData: null,
        checkAuditStart: "",
        checkoutdata: {},
        futureCheckouts: [],
        sub: "",
        auditDate: "",
        bookingCheck:'none',
        timezonename:'',
      };
    }

    convertFt = (text) => {
      // text = text.charAt(0).toUpperCase() +
      // text.slice(1)
      // text = text.charAt(0).toUpperCase() +
      // text.slice(1)
      if (/\b\w/g.test(text)) {
        text = text.replace(/\b\w/g, function (match) {
          return match.toUpperCase();
        });
      }
      if (/(-\w)/g.test(text)) {
        text = text.replace(/(-\w)/g, function (match) {
          return match.toUpperCase();
        });
      }
      text = text.replace(/[-_]/g, " ");
      return text;
    };

    componentDidMount() {
      this.getAuditTime();
      this.getApi();

      const token = localStorage.getItem("logintoken");
      const prop = localStorage.getItem("property");
      if (token === null && prop === null) {
        return (window.location.href = "/login");
      }

      if (prop === null) {
        return (window.location.href = "/selectproperty");
      }

      let dict = this.passTitle();

      this.setState({ name: dict["name"] });
      this.setState({ title: dict["title"] });
      this.setState({ head: dict["head"] });
      this.setState({ sub: dict["sub"] });

      // console.log(this.state.dName);
      if (this.state.dName === "Dashboard") {
        this.setState({ title: this.state.dName });
        this.setState({ name: this.state.dName });
        this.setState({ head: "Dashboard" });
        this.setState({ sub: "Dashboard" });
      } else if (this.state.dName === "Permission Management") {
        this.setState({ title: "Permission Management" });
        this.setState({ name: "Roles & Permission >> Permission Management" });
        this.setState({ head: "Roles & Permission" });
        this.setState({ sub: "Permission Management" });
      } else if (this.state.dName === "Profile") {
        this.setState({ title: "Profile" });
        this.setState({ name: "Dashboard >> Profile" });
        this.setState({ head: "Dashboard" });
        this.setState({ sub: "Profile" });
      } else if (this.state.dName === "change-password?id=97") {
        this.setState({ title: "Profile" });
        this.setState({ name: "Dashboard >> Profile >> Change  Password" });
        this.setState({ head: "Profile" });
        this.setState({ sub: "Change Password" });
      } else if (this.state.dName === "Manage Roles") {
        this.setState({ title: "Manage Roles" });
        this.setState({ name: "Roles & Permission >> Manage Roles" });
        this.setState({ head: "Roles & Permission" });
        this.setState({ sub: "Manage Roles" });
      } else if (this.state.dName === "Activity") {
        this.setState({ title: "Activity" });
        this.setState({ name: "Dashboard >> Activity" });
        this.setState({ head: "Activity" });
        this.setState({ sub: "Activity" });
      } else if (this.state.dName === "Reservations") {
        this.setState({ title: "Reservations" });
        this.setState({ name: "Dashboard >> Reservations" });
        this.setState({ head: "Reservations" });
        this.setState({ sub: "Dashboard-Reservations" });
      } else if (this.state.dName === "Occupancies") {
        this.setState({ title: "Occupancy Status" });
        this.setState({ name: "Dashboard >> Occupancy Status" });
        this.setState({ head: "Occupancy Status" });
        this.setState({ sub: "Occupancy Status" });
      } else if (this.state.dName === "Booking Details") {
        this.setState({ title: "Booking Details" });
        this.setState({ name: "Dashboard >> Booking Details" });
        this.setState({ head: "Booking Status" });
        this.setState({ sub: "Booking Status" });
      } else if (this.state.dName === "Rooms") {
        this.setState({ title: "Rooms" });
      } else if (this.state.dName === "Notes") {
        this.setState({ title: "Notes" });
        this.setState({
          name: "Amenities Management >> Amenities Status >> Notes",
        });
        this.setState({ head: "Notes" });
        this.setState({ sub: "Notes" });
      } else if (this.state.dName === "Edit Property") {
        this.setState({ title: "Edit Property" });
        this.setState({
          name: "Options >> Property Management >> Edit Property",
        });
        this.setState({ head: "Edit Property" });
        this.setState({ sub: "Edit Property" });
      } else if (this.state.dName === "Add Policy") {
        this.setState({ title: "Add Policy" });
        this.setState({ name: "Options >> Policy Management >> Add Policy" });
        this.setState({ head: "Add Policy" });
        this.setState({ sub: "Add Policy" });
      } else if (this.state.dName === "Edit Policy") {
        this.setState({ title: "Edit Policy" });
        this.setState({ name: "Options >> Policy Management >> Edit Policy" });
        this.setState({ head: "Edit Policy" });
        this.setState({ sub: "Edit Policy" });
      } else if (this.state.dName === "Cancellation Process") {
        this.setState({ title: "Cancellation" });
        this.setState({ name: "Bookings >> Cancellation" });
        this.setState({ head: "Cancellation Process" });
        this.setState({ sub: "Cancellation Process" });
      } else if (this.state.dName === "Extend Stay") {
        this.setState({ title: "Extend Stay" });
        this.setState({ name: "Bookings >> Extend Stay" });
        this.setState({ head: "Extend Stay" });
        this.setState({ sub: "Extend Stay" });
      } else if (this.state.dName == "Extend Stay Process") {
        this.setState({ title: "Extend Stay Process" });
        this.setState({ name: "Bookings >> Extend Stay Process" });
        this.setState({ head: "Extend Stay Process" });
        this.setState({ sub: "Extend Stay Process" });
      } else if (this.state.dName == "Registration Form") {
        this.setState({ title: "Registration Form" });
        this.setState({ name: "Bookings >> Registration Form" });
        this.setState({ head: "Registration Form" });
        this.setState({ sub: "Registration Form" });
      } else if (this.state.dName == "Payment Form") {
        this.setState({ title: "Payment Form" });
        this.setState({ name: "Bookings >> Payment Form" });
        this.setState({ head: "Payment Form" });
        this.setState({ sub: "Payment Form" });
      } else if (this.state.dName == "Refund") {
        this.setState({ title: "Refund" });
        this.setState({ name: "Payment >> Refund" });
        this.setState({ head: "Refund" });
        this.setState({ sub: "Refund" });
      } else if (this.state.dName == "Room-Booking") {
        this.setState({ title: "Room-Reservation" });
        this.setState({ name: "Room-Reservation >> Room-Booking" });
        this.setState({ head: "Room-Reservation" });
        this.setState({ sub: "Room-Reservation" });
      } else if (this.state.dName == "Audit Process") {
        this.setState({ title: "Audit" });
        this.setState({ name: "Audit Process" });
        this.setState({ head: "Audit Process" });
        this.setState({ sub: "Audit Process" });
      } else if (this.state.dName == "Inventory View") {
        this.setState({ title: "Room View" });
        this.setState({ name: "Room View" });
        this.setState({ head: "Room View" });
        this.setState({ sub: "Room View" });
      }
      else if (this.state.dName == "Change Reservation Date") {
        this.setState({ title: "Change Reservation Date" });
        this.setState({ name: "Reservation >> Change Date" });
        this.setState({ head: "Change Reservation Date" });
        this.setState({ sub: "Change Reservation Date" });
      }
      
      // console.log(this.state.dName);
      // console.log(this.state);

      // setTimeout(() => {
      //     this.setState(() => ({
      //         pageLoaded: true
      //     }))
      // }, 1000);
    }

    passTitle = () => {
      let location = window.location.pathname;
      let title, name, arr, replace, dict;
      let otherPart;
      dict = {
        name: "",
        title: "",
        head: "",
        sub: "",
      };
      SidebarData.map((data) => {
        Object.keys(data.subheading).map((sub) => {
          otherPart = ChildSide[data.heading];

          if (location.includes(sub)) {
            // console.log(sub, location);
            let a = ConvertLatter(data.subheading[sub][0], "first");
            let check = localStorage.getItem("user_permission");
            if (check && check !== null) {
              let per = JSON.parse(check)[a];
              this.setState({ permission: per });
              // console.log(":here");
            }

            title = sub;

            if (location === `/${title}`) {
              if (title.includes("_")) {
                title = title.replace("_", " ");
              }
              if (title.includes("-")) {
                title = title.replace("-", " ");
              }
              title = this.convertFt(title);

              name = `${this.convertFt(data.heading)} >> ${this.convertFt(
                title
              )}`;

              dict = {
                name: name,
                title: title,
                head: data.heading,
                sub: sub,
              };
            }
          }
          if (otherPart !== undefined) {
            if (
              otherPart[ConvertLatter(data.subheading[sub][0], "first")] !==
              undefined
            ) {
              otherPart =
                otherPart[ConvertLatter(data.subheading[sub][0], "first")];

              arr = Object.keys(otherPart);

              arr.map((item) => {
                replace = item.split("__")[0];

                if (location.includes(replace)) {
                  title = sub;
                  if (replace.includes("_")) {
                    title = title.replace("_", " ");
                  }
                  if (title.includes("-")) {
                    title = title.replace("-", " ");
                  }
                  if (replace.includes("_")) {
                    replace = replace.replace("_", " ");
                  }
                  if (replace.includes("-")) {
                    replace = replace.replace("-", " ");
                  }
                  // console.log("hhe00");
                  name = `${this.convertFt(data.heading)} >> ${this.convertFt(
                    title
                  )} >> ${this.convertFt(replace)}`;

                  // console.log(name);

                  dict = {
                    name: name,
                    title: this.convertFt(replace),
                    head: this.convertFt(data.heading),
                    sub: sub,
                  };
                }
              });
            }
          }
        });
      });
      return dict;
    };

    checkOld = (Uname = null, Role = null) => {
      let name = sessionStorage.getItem("name");
      let role = sessionStorage.getItem("role");
      if (name != null && role != null) {
        this.setState({ roleName: name });
        this.setState({ role: role });
      } else if (Uname != null && Role != null) {
        sessionStorage.setItem("name", Uname);
        sessionStorage.setItem("role", Role);
        this.setState({ roleName: Uname });
        this.setState({ role: Role });
      }
      if (Uname != null && name != null) {
        if (Uname !== name) {
          sessionStorage.removeItem("name");
        }
      }
      if (Role != null && role != null) {
        if (Uname !== name) {
          sessionStorage.removeItem("role");
        }
      }
    };

    getApi = async () => {
      this.checkOld();
      // const leftValue = JSON.parse(localStorage.getItem("collapsed"));
      // this.setState({leftValue:leftValue})
      let check = false;
      let property = localStorage.getItem("property");
      let url = `${this.state.url}timeZone-show/${property}`;

      let data = await ApiCall(url, "get");
      console.log(data,'datach');
      console.log(data.time,'time');
      
      
      

      if (data.code === "token_not_valid") {
        localStorage.removeItem("logintoken");
      }
      if (data.code === "user_not_found") {
        localStorage.removeItem("logintoken");
      }
      if ((data.check = "not exists")) {
        check = true;
      }
      localStorage.setItem("oneTime", check);
      sessionStorage.setItem("role", data?.role);

      sessionStorage.setItem("time", data?.time); // Set Date and Time in Session Storage for Dates in Report
      sessionStorage.setItem("date", data?.date);
      localStorage.setItem("date", data?.date);

      this.setState({bookingCheck:data.booking});
      this.setState({ date: data.date });
      this.setState({timezonename:data['timeZone-name']})
      this.setState({ time: data.time });
      this.setState({ pageLoaded: true });
      this.setState({ auditDate: data.audit_date });
       setTimeout(() => {
        this.setState({ timeloaded: true });
      }, 8000);
      this.checkOld(data.name, data.role);
      this.setState({ checkAuditStart: data });
    };


    renderHtml() {
      if (!this.state.pageLoaded) {
        //     return <div className="loading-page">
        //         <div className="center">
        //             <Preloader use={Bars} size={60} strokeWidth={10} strokeColor="#f7b085" duration={600} />
        //         </div>
        //   </div>
      }
      // const leftValue = JSON.parse(localStorage.getItem("collapsed"));
      // console.log(this.state.leftValue,"LEFTVALUE");


 
  
      return (
        <>
          {localStorage.getItem("property") ? (
           this.state.timezonename !=''? <>{

            <div className="d-flex dash-responsive" id="wrapper">
            {/* <!-- Sidebar--> */}
            <Sidebar url={this.state.url} />
            {/* <!-- Page content wrapper--> */}
            <div className="main  mt-5" id="page-content-wrapper" >
              {/* <!-- Top navigation--> */}

             
                <Header
                  url={this.state.url}
                  auditDate={this.state.auditDate}
                  date={this.state.date}
                  roleName={this.state.roleName}
                  role={this.state.role}
                  head={this.state.head}
                  time={this.state.time}
                  sub={this.state.sub}
                  name={this.state.name}
                  title={this.state.title}
                  pageLoaded={this.state.pageLoaded}
                  timeloaded={this.state.timeloaded}
                />
              

              {/* <!-- Page content--> */}
              <div className="container-fluid content-container">
                <ChildComponent
                  timeZonename={this.state.timezonename}
                  auditCompleted={this.state.bookingCheck}
                  auditData={this.state.auditData}
                  time={this.state.time}
                  auditDate={this.state.auditDate}
                  permission={this.state.permission}
                  {...this.props}
                />
              </div>
              <div className="mt-5"></div>
            </div>
          </div>

           }</>:<Loading/>
          ) : (
            <div className="d-flex justify-content-center align-items-center pt-4">
              <p>Please Select Property First. Redirecting...</p>
            </div>
          )}
        </>
      );
    }

    addLeadModalFooterContent() {
      return (
        <>
          <div style={{ width: "100%" }}>
            <button
              onClick={(e) =>
                this.setState(() => ({
                  saveLeadClickEvent: (Math.random() + 1)
                    .toString(36)
                    .substring(7),
                }))
              }
              className="btn btn-default low-height-btn"
            >
              Add Lead
            </button>
          </div>
        </>
      );
    }

   
    getAuditTime = async () => {
      const property = localStorage.getItem("property");

      let data = await ApiCall(
        `${this.state.url}AuditProcess/${property}`,
        "get"
      );
      this.setState({ auditData: data?.data });

      // sessionStorage.setItem('enabled', auditData?.data?.enabled);
      // setTimeAllowed(auditData?.data?.allowed_time)
      // setTimer(auditData?.data?.timer)
      // setEnabled(auditData?.data?.enabled)
      // setStartTime(auditData?.data?.start_time)
    };

    handleStartAudit(check) {
      // const prop = localStorage.getItem('property')
      // console.log(check, "sksksksksksksks");
      // const currentAudit = await ApiCall(`${this.state.url}AuditProcess/${prop}`, "post",{
      //     method:'start_audit'
      //   });
      if (check === "checkout") {
        this.setState({ auditpage: true, check: true });
      } else {
        this.setState({ auditpage: true, check: false });
      }
    }
    redirectAudit() {
      if (this?.state?.auditpage && !this.state.check) {
        // console.log(this.state, "null");
        return <Navigate to="/Audit" state={{ audit: true }} />;
      }
      if (this?.state?.auditpage && this.state.check) {
        // console.log("true", this.state);
        return <Navigate to="/Key-Reports" state={"In-House Report"} />;
      }
    }

    sendNotification(check) {
      toast.success(
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 14, fontWeight: "bold", color: "black" }}>
              {check === "checkout"
                ? "Please ensure all pending guest checkouts are completed and review their status to confirm all departures are accurately recorded"
                : "Your audit time has passed. Please start your audit."}
            </p>
            {window?.location.pathname !== "/Audit" ? (
              <button
                style={{
                  background: "#1AB64F",
                  color: "white",
                  borderRadius: 10,
                  width: 130,
                  height: 30,
                  fontSize:14,
                  border: "none",
                }}
                onClick={() => this.handleStartAudit(check)}
              >
                {check === "checkout" ? "Checkout Users" : "Start Audit"}
              </button>
            ) : (
              ""
            )}
          </div>
          <div style={{ position: "absolute", right: '3%' }}>
            <button
              style={{ border: "none", background: "none" }} // Add a class for styling
              onClick={() => toast.dismiss()} // Dismiss the toast when clicked
            >
              &times;
            </button>
          </div>
        </>,
        {
          position: "top-right",
          duration: 120000, // 2 mnutes
          style: {
            borderRadius: "10px",
            background: "white",
            color: "#fff",
            animation: "slideInRight",
          },
          // Optionally, you can add an onClick handler to the toast
        }
      );

      // Retrieve the timer value from sessionStorage

      // Check if the timer has reached 0

      // cogoToast.success(
      //     <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      //         <p style={{ fontSize: 15, fontWeight: "bold" }}>Your audit time has passed. Please start your audit.</p>
      //         {window?.location.pathname !=='/Audit' ? <>
      //         <button style={{ background: "#1AB64F", border: "none", color: "white", borderRadius: 10, width: 90, height: 40 }} onClick={this.handleStartAudit}>Start Audit</button>
      //         </> :""}
      //     </div>,
      //     {
      //         position: 'top-right' // Specify the position of the notification
      //     },
      //     {
      //         hideAfter: 7000
      //     }
      // );

      // Set notificationShown to true to prevent multiple notifications
    }
    renderCheckouttimer(prevState) {
      let timer =
        parseInt(sessionStorage.getItem("checkouttimer")) || checkoutTimer;
      if (timer > 0 && !this.state.intervalCheckout) {

        const intervalCheckout = setInterval(() => {
          if (timer > 0) {

            // const leftValue = JSON.parse(localStorage.getItem("collapsed"));
            // this.setState({leftValue:leftValue})

            timer--;
            sessionStorage.setItem("checkouttimer", timer.toString());
          }

          if (timer === 0) {
            this.sendNotification("checkout");
            timer = checkoutTimer;

            // Store the updated timer in sessionStorage
            sessionStorage.setItem("checkouttimer", timer.toString());
          }
        }, 1000); // Update timer every second
        this.setState({ intervalCheckout });
      }
    }
    checkoutNotification(prevState) {
      const filteredData = this.state?.checkAuditStart?.checkout
        ? Object.entries(this.state.checkAuditStart.checkout)
          .filter(([key, value]) => {
            // Check if the status is "Checked-in" and checkout date is earlier than today's date
            return (
              value.status === "Checked-in" &&
              value.checkout_date < this?.state?.checkAuditStart?.date
            );
          })
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {})
        : null;
      if (filteredData && Object?.keys(filteredData).length > 0) {
        this.renderCheckouttimer(prevState);
      }
    }

    componentDidUpdate(prevProps, prevState) {

      // const leftValue = JSON.parse(localStorage.getItem("collapsed"));

      // Only update the state if leftValue has changed
      // if (prevState.leftValue !== leftValue) {
      //   this.setState({ leftValue });
      // }

      // console.log(this.state.leftValue, "Leftvalue");



      this.checkoutNotification(prevState);
      const currentTime = moment().format("HH:mm A");
      console.log(currentTime,'curr');
      

      // Convert start_time to AM/PM format
      const startTimeAMPM = moment(
        this?.state?.auditData?.start_time,
        "HH:mm"
      ).format("HH:mm A");

     
      // Retrieve the timer value from sessionStorage
      if (
        this.state.auditData?.enabled &&
        this?.state?.checkAuditStart?.auditor?.start === null &&
        currentTime > startTimeAMPM
      ) {
        let timer =
          parseInt(sessionStorage.getItem("notificationTimer")) ||
          notificationTImer;

        // Start the timer if it's greater than 0 and hasn't been started yet
        if (timer > 0 && !this.state.intervalId) {
          // Update the timer every second
          const intervalId = setInterval(() => {
            // Check if the timer is greater than 0 before decrementing
            if (timer > 0) {
              timer--;

              // Store the updated timer in sessionStorage
              sessionStorage.setItem("notificationTimer", timer.toString());
            }

            if (timer === 0) {
              // Check if the current time exceeds the start time
              this.checkStartTime();

              // Reset the timer to 10 seconds
              timer = notificationTImer;

              // Store the updated timer in sessionStorage
              sessionStorage.setItem("notificationTimer", timer.toString());
            }
          }, 1000); // Update timer every second

          // Store the interval ID in state
          this.setState({ intervalId });
        }
      }

      // Update sessionStorage only when the timer value changes
      // if (timer !== this.state.timer) {
      //     this.setState({ timer });
      // }
    }

    componentWillUnmount() {
      // Clear the interval when the component is unmounted
      clearInterval(this?.state?.intervalId);
      clearInterval(this?.state?.intervalCheckout);
    }
    checkStartTime() {
      // Get current time in HH:mm A format (AM/PM)
      const currentTime = moment().format("HH:mm A");
      console.log(currentTime,'curr');
      

      // Convert start_time to AM/PM format
      const startTimeAMPM = moment(
        this?.state?.auditData?.start_time,
        "HH:mm"
      ).format("HH:mm A");
      
      

      // Check if current time has exceeded start_time and audit is not started
      if (
        currentTime > startTimeAMPM &&
        this?.state?.auditData?.enabled &&
        this?.state?.checkAuditStart?.auditor?.start === null
      ) {
        this.sendNotification();
      }
    }

    render() {
      // console.log("future", this.state);
      return (
        <>
          <Toaster
            toastOptions={{
              style: {
                width: "600px", // Set the width as per your requirement
              },
            }}
          />
          {this.renderHtml()}
          {this.redirectAudit()}
        </>
      );
    }
  }

  return AdminLayout;
};

export default adminLayout;

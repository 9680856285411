import React, { useState, useEffect, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import popup from "../../assets/css/popup.module.css"
import OnlyMin from './OnlyMin';
import ConvertLatter from './ConvertLatter';
import OnlySecond from './OnlySecond';
import "../../assets/css/form_x.css"



function AlertDismissible(props) {
  const [show, setShow] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const alertRef = useRef(null);


  useEffect(() => {
    if (props.msg) {
      setShowAlert(true); // Reset the alert visibility when a new message comes in
    }
  }, [props.msg]);


  useEffect(() => {
    console.log("here ms a","asTime")
    if (props.time !== undefined) {
      setTimeout(() => {
        props.msgFun()
        setShowAlert(false)
      }, 5000);
    }
    else if (props.asTime !== undefined) {
   
      const asTime = parseInt(props.asTime) * 1000
      console.log("time",asTime)
      console.log("here ms a",asTime)
      setTimeout(() => {
        if(showAlert){
          props.msgFun()
          setShowAlert(false)
        }
      }, asTime);
    }
    else {
      setTimeout(() => {
        props.msgFun()
        setShowAlert(false)
      }, 5000);
    }

    const handleWindowClick = (e) => {

      if (alertRef.current && !alertRef.current.contains(e.target)) {
        if(props.notT === undefined){
          setShowAlert(false);
          props.msgFun()
        }
     
       
      }

    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };



  }, [props]);

  return (
    showAlert &&
    <>
      <div className="modal active" id="delete_room" tabIndex="-1" role="dialog" aria-labelledby="delete_room" aria-hidden="false" style={{ display: "block",width:props.frommodel2 ? '650px' : '100%' }}>
        <div className="modal-dialog alertclassname" role="document" style={{left:'50%'}}>
          <div ref={alertRef} style={{ borderRadius: "20px", width: '600px;', textAlign: "center",marginLeft:props.frommodel2 ? '100px' : '0px' }} className={`modal-content ${popup[props.type]}`}>
            <div className="modal-body" >
              {props.type === "success" ?
                (<img alt='Popup' src={require('./../../assets/images/popup1-icon.png')}></img>)
                : (
                  props.type === 'notice' ? <img style={{ width: 50, height: 50 }} src={require('./../../assets/images/warning.png')} /> : <img alt='Popup' src={require('./../../assets/images/popup2-icon.png')}></img>
                )

              }
              {props.timerShow !== undefined &&
                <div style={{ marginTop: '12px' }}>
                  <OnlyMin minutes={props.timerShow} />
                </div>
              }
              {props.secondShow !== undefined &&
                <div style={{ marginTop: '12px' }}>
                  <OnlySecond seconds={10} />
                </div>
              }
              {console.log("here ms", ConvertLatter(props.msg, 'capital'))}
              <p className='mt-3'><b>{ConvertLatter(props.msg, 'capital')}</b></p>
              {/* <p className='mt-3'><b>{props.msg}</b></p> */}
              <button onClick={() => { setShowAlert(false) }} type="button" className="btn" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlertDismissible 
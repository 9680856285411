import dashboardimage from "../../assets/images/pana.png"
import step2image from "../../assets/images/portfolio.png"
import step3image from "../../assets/images/car.png"
import step4image from "../../assets/images/done.png"
import step5image from "../../assets/images/stats.png"
import step6image from "../../assets/images/money.png"
import step7image from "../../assets/images/computer.png"
import step8image from "../../assets/images/windows.png"
import step9image from "../../assets/images/resume.png"
import step10image from "../../assets/images/timemana.png"


const isWindowWideEnough = window.innerWidth > 1270;
console.log(isWindowWideEnough)
export default [

  {
    id: "welcome",
    scrollTo: {
      behavior: 'smooth',
      // or 'end' or 'center'
    },
    text: [
      `
       <div class='useronboardingstep1'>
       <div style="text-align: center;">
       <img src="${dashboardimage}" alt="Image description" />
      </div>
      <div class='userstep1text'>
      “Welcome to our hotel management system! We’re excited to guide you through your first-time experience with our software.”
      </div>
       </div>
      `
    ],
    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        type: "next",
        text: "Next",

      },
      {
        text: "x",
        action: function () {
          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      }



    ],

  },
  {
    id: "Arrival",
    highlightClass: 'highlighted-element',

    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Home Features</div>
         <div style="text-align: center;">
         <img src="${step2image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        Home page features show arrivals, departures , stay-overs and accommodation percentage for quick user assessment.
        </div>
         </div>
        `
    ],
    when: {
      show: () => {
        // document.querySelector(".overflowDashboardcard").classList.remove("highlighted-element");
        function scrollToTop() {
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
          window.scrollTo(0, 0);
        }
        scrollToTop()
      },
      hide: () => {


      }
    },
    classes: 'step2class',
    canClickTarget: false,
    attachTo: { element: "#highlightdetailsstep2", on: "bottom" },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back',

      },
      {
        type: "next",
        text: "Next",
        classes: 'nextbuttons'
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      }
    ]
  },
  {
    id: "details",
    canClickTarget: false,
    
    when: {
      show: () => {
        // document.querySelector(".overflowDashboardcard").classList.remove("highlighted-element");
        function scrollToTop() {
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
          window.scrollTo(0, 0);
        }
        scrollToTop()
      },
      hide: () => {


      }
    },
    classes: 'step3class',
    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Quick Access</div>
         <div style="text-align: center;">
         <img src="${step3image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        Quick access tab is a shortcut menu that provides fast access to major features
        </div>
         </div>
        `
    ],
    attachTo: { element: ".overflowDashboardcard", on: "bottom" },
    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      },

    ],

  },
  {
    id: "reserve",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },
    classes: 'step4class',
    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Quick Access</div>
         <div style="text-align: center;">
         <img src="${step4image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        New reservation features allows users to create fresh bookings quickly and easily
        </div>
         </div>
        `
    ],
    attachTo: { element: "#headercardreservation", on: isWindowWideEnough ? "right" : "bottom", },
    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      },
    ]
  },
  {
    id: "reservation",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },
    classes: 'step5class',
    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Reservation Tab</div>
         <div style="text-align: center;">
         <img src="${step5image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        The Reservation Tab streamlines essential functions offering a unified view of all reservation-related features.
        </div>
         </div>
        `
    ],
    attachTo: { element: ".highlightreservationpage", on: isWindowWideEnough ? "right" : "bottom", },
    when: {
      show: () => {
        // document.querySelector(".overflowDashboardcard").classList.remove("highlighted-element");

        document.querySelector(".reservationdottedline").classList.add("highlighted-element");
        document.querySelector(".reservationdottedline1").classList.add("highlighted-element");
        document.querySelector(".reservationdottedline2").classList.add("highlighted-element");
        document.querySelector(".reservationdottedline3").classList.add("highlighted-element");



      },
      hide: () => {

        document.querySelector(".reservationdottedline").classList.remove("highlighted-element");
        document.querySelector(".reservationdottedline1").classList.remove("highlighted-element");
        document.querySelector(".reservationdottedline2").classList.remove("highlighted-element");
        document.querySelector(".reservationdottedline3").classList.remove("highlighted-element");
      }
    },
    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          document.querySelector(".reservationdottedline").classList.remove("highlighted-element");
          document.querySelector(".reservationdottedline1").classList.remove("highlighted-element");
          document.querySelector(".reservationdottedline2").classList.remove("highlighted-element");
          document.querySelector(".reservationdottedline3").classList.remove("highlighted-element");
          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      },
    ]
  },
  {
    id: "todayactivity",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },
    classes: 'step6class',
    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Today's Activity</div>
         <div style="text-align: center;">
         <img src="${step6image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        Today’s Activity provides an overview of the day’s booking, room night count , revenue generated, and an option to view all related details.
        </div>
         </div>
        `
    ],
    attachTo: { element: ".todaysacitivityhighlight", on: isWindowWideEnough ? "right" : "bottom", },
    when: {
      show: () => {

        document.querySelector("#bookedtodayhighlight").classList.add("highlighted-element");
        document.querySelector("#roomnighthighlight").classList.add("highlighted-element");
        document.querySelector("#revenuehighlight").classList.add("highlighted-element");
      },
      hide: () => {
        // You can remove any additional handling for hiding the step here.

        document.querySelector("#bookedtodayhighlight").classList.remove("highlighted-element");
        document.querySelector("#roomnighthighlight").classList.remove("highlighted-element");
        document.querySelector("#revenuehighlight").classList.remove("highlighted-element");
      }
    },

    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          document.querySelector("#bookedtodayhighlight").classList.remove("highlighted-element");
          document.querySelector("#roomnighthighlight").classList.remove("highlighted-element");
          document.querySelector("#revenuehighlight").classList.remove("highlighted-element");
          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      },
    ]
  },
  {
    id: "questionmark",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },
    classes: 'step7class',
    highlightClass: 'highlighted-elements',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Question Mark Icon</div>
         <div style="text-align: center;">
         <img src="${step7image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        The Question mark icon provides access to video tutorials for our hotel management system offering helpful guidance on system use.
        </div>
         </div>
        `
    ],
    attachTo: { element: "#highlightquestionmark" },
    when: {
      show: () => {
        // highlightnewiconquestion
        document.querySelector(".highlightnewiconquestion").classList.add("highlighted-element");

      },
      hide: () => {
        // You can remove any additional handling for hiding the step here.

        document.querySelector(".highlightnewiconquestion").classList.remove("highlighted-element");

      }
    },

    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked
          document.querySelector(".highlightnewiconquestion").classList.remove("highlighted-element");
          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      }
    ]
  },
  {
    id: "sidebar",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },
    classes: 'step8class',
    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Sidebar</div>
         <div style="text-align: center;">
         <img src="${step8image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        Sidebar offers quick access to additional features, information, or navigation option without taking up the main screen space.
        </div>
         </div>
        `
    ],
    attachTo: { element: "#sidebar-wrapper", on: "right" },


    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      }
    ]
  },
  {
    id: "logout",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },
    classes: 'custom-popup',
    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Profile</div>
         <div style="text-align: center;">
         <img class='userboardimage' src="${step9image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        This function gives user ability to update their personal information, Change property, and perform a logout.
        </div>
         </div>
        `
    ],
    attachTo: { element: ".fixed-bottom-dropdown" },


    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Next",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      }
    ]
  },
  {
    id: "time",
    canClickTarget: false,
    scrollTo: {
      behavior: 'smooth',
    },

    highlightClass: 'highlighted-element',
    text: [
      `
         <div class='useronboardingstep1'>
         <div class='homefeaturehighlight'>Time and Date</div>
         <div style="text-align: center;">
         <img class='userboardimage' src="${step10image}" alt="Image description" />
        </div>
        <div class='userstep1text'>
        User can view the real-time date and conveniently navigate to the profile menu, where they have the option to edit their personal information and update property details.
        </div>
         </div>
        `
    ],
    attachTo: { element: "#profilepichighlight" },


    buttons: [
      // {
      //   type: "cancel",
      //   classes: "shepherd-button-secondary",
      //   text: "Exit"
      // },
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        type: "next",
        text: "Lets get you started",
      },
      {
        text: "x",
        action: function () {
          // Add your custom action when the custom close button is clicked

          this.cancel();
        },
        classes: "custom-close-button", // Add a custom CSS class
      }
    ]
  }


];

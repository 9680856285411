// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_success__HrQYe{
    background: #D9FFDF !important;
    color:#1AB64F;
    width: 400px !important;
}
.popup_notice__KunmH{
    background: rgba(255, 246, 214, 1) !important;
    color: rgba(176, 142, 22, 1);
}
.popup_success__HrQYe button{
    background: #1AB64F !important;
    color:white;
    border-radius: 5px;
}
.popup_notice__KunmH button{
    background: rgba(255, 205, 26, 1) !important;
    color:white;
    border-radius: 5px;
}

.popup_danger__ueCe4{
    background: #FFE3E3 !important;
    color:#D32F2F;
    width: 400px !important;
}
.popup_danger__ueCe4 button{
    background: #D32F2F !important;
    color:white;
    border-radius: 5px;
}

.popup_danger__ueCe4 img, .popup_success__HrQYe img{
    width: 30%;
}


`, "",{"version":3,"sources":["webpack://./src/assets/css/popup.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,6CAA6C;IAC7C,4BAA4B;AAChC;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,4CAA4C;IAC5C,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".success{\n    background: #D9FFDF !important;\n    color:#1AB64F;\n    width: 400px !important;\n}\n.notice{\n    background: rgba(255, 246, 214, 1) !important;\n    color: rgba(176, 142, 22, 1);\n}\n.success button{\n    background: #1AB64F !important;\n    color:white;\n    border-radius: 5px;\n}\n.notice button{\n    background: rgba(255, 205, 26, 1) !important;\n    color:white;\n    border-radius: 5px;\n}\n\n.danger{\n    background: #FFE3E3 !important;\n    color:#D32F2F;\n    width: 400px !important;\n}\n.danger button{\n    background: #D32F2F !important;\n    color:white;\n    border-radius: 5px;\n}\n\n.danger img, .success img{\n    width: 30%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `popup_success__HrQYe`,
	"notice": `popup_notice__KunmH`,
	"danger": `popup_danger__ueCe4`
};
export default ___CSS_LOADER_EXPORT___;

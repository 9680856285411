const ChildSide = {
  'Room Management': {
    'Room-Details': {
      Room_view__view: [],
      bookings__edit: [],
    },
    Rates: {
      base_rates__send: [],
    },
    'Room-Types': {
      'Add-Room-Type__send': [],
      'Edit-Room-Type__edit': [],
    },
  },
  'Amenities Management': {
    'Hotel-Inventory': {
      'add-Amenities__send': [],
      'edit-Amenities__edit': [],
    },
  },
  'Reservation Management': {
    'Check-In-Check-Out': {
      'Room-Change__send': [],
    },
  },
  Options: {
    'Policy-Management': {
      addPolicy__send: [],
      editPolicy__edit: [],
    },
  },
};

export default ChildSide;
